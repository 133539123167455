<template>
  <div class="container p-tb-2">
    <div class="row">
      <div class="col-lg-12 col-xs-12 how-it-works">
        <h1>How it works</h1>
        <p>
          The products from our online catalog are available for wholesale and white labeling. For a more convenient
          search, you can filter products by capacity, price, and MOQ.
        </p>
        <p>
          Find out all the important details about a certain product in a few clicks. Select a product to see its
          specifications, photo, MOQ, and price.
        </p>
        <p>
          You need to sign up or sign in to be able to order a product and specify details. If you need any advice or
          additional information, please, contact us via email, contact form, or Instagram.
        </p>
        <div class="steps">
          <template v-for="(step, i) in steps">
            <div class="tile" :key="i">
              <div class="tile-header">
                <span class="tile-title">{{ step.title }}</span>
                <span class="tile-subtitle">{{ step.subtitle }}</span>
              </div>
              <div class="tile-image">
                <img :src="`/img/how/${step.id}.svg`" :alt="step.id" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetaMixin from 'Lib/mixins/MetaMixin';

export default {
  name: 'HowItWorks',
  mixins: [MetaMixin],
  data() {
    return {
      steps: [
        {
          id: 'browse',
          title: 'Browse through 600+ products',
        },
        {
          id: 'select',
          title: 'Select a product',
        },
        {
          id: 'customize',
          title: 'Customize',
          subtitle: 'add a custom logo, color, and packaging',
        },
        {
          id: 'production',
          title: 'Production',
          subtitle: 'we will take over the production, quality control, shipping, and customs',
        },
        {
          id: 'receive',
          title: 'Receive product',
          subtitle: 'each package will include a heavy metal test report',
        },
      ],
    };
  },
  beforeMount() {
    this.$store.dispatch('setBreadcrumbs', [{ to: this.$route.path, title: 'How It Works' }]);
  },
};
</script>

<style type="text/css" lang="scss" scoped>
p {
  //font-weight: 500;
}

.how-it-works {
  .steps {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: space-between;
    margin-top: 35px;

    .tile {
      width: 200px;
      height: 320px;
      //background-color: #e6e7e9;
      background-color: white;
      padding: 15px;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-between;
      position: relative;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      margin-right: 10px;

      &:nth-child(even) {
        //background-color: #f2f3f4;
      }

      &:not(:last-child):after {
        content: '';
        display: block;
        position: absolute;
        right: -24px;
        top: 20%;
        width: 36px;
        height: 36px;
        background: url(/img/arrow.svg) no-repeat;
        z-index: 2;
      }

      &-header {
        .tile-title {
          font-size: 24px;
          font-weight: 600;
          display: block;
          margin-bottom: 10px;
        }

        .tile-subtitle {
        }
      }

      &-image {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: hue-rotate(5deg);

        img {
          height: 100%;
        }
      }
    }
  }
}
</style>
